@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400&family=Roboto:wght@300;400&display=swap');
html, body {
  width: 100%;
  height: 100vh;
  -webkit-overflow-scrolling: touch;
  margin: 0;
  padding: 0;
}
body {
  height: 100vh;
  overflow: hidden;
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //color: #FF0078;
  color: #FFF;
  background-image: url('https://theludlowgroup.com/content/images/2018/05/Liminal_Space_Truck_Stop.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  backdrop-filter: blur(8px);
}
.bg1 {
  background-image: url('https://theludlowgroup.com/content/images/2018/05/Liminal_Space_Truck_Stop.jpg');
}

.bg2 {
  background-image: url('https://pm1.narvii.com/6805/3a0762ed87b1d7287b7cc28236b521fc3a888bfbv2_hq.jpg');
}

.bg3 {
  background-image:  url('https://thumbs.dreamstime.com/b/road-park-misty-night-road-park-misty-night-atmosphere-looks-mysterious-beautiful-same-215072515.jpg');
}

.bg4 {
  background-image: url("./imgs/DSC_0121.JPG");
}
/*.bg5 {
  background-image: url('https://images.fineartamerica.com/images/artworkimages/mediumlarge/2/nighttime-view-of-an-empty-side-street-amayfoto.jpg');
}*/
.bg5 {
  background-image: url('https://c0.wallpaperflare.com/preview/32/826/437/fun-odaiba-quiet-desolate.jpg');
}
.bg6 {
  background-image: url("./imgs/bg6.jpg");
}
h2 {
  font-weight: 300;
  letter-spacing: 2px;
  font-size: 1.5em;
  white-space: nowrap;
  display: flex;
  flex-shrink: 1;
  width: 100%;
  text-align: center;
  align-content: center;
  flex-direction: column;
}
@media all and (max-device-width: 410px){
    h2 {
        font-size: 1em;
    }
}
a {
  color: #75ffbe;
  text-decoration:  none;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
}

a:hover {
  color: #75ffbe;
  text-decoration:  underline;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
