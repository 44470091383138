@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
.App {
  text-align: center;
  overflow: hidden;
  height: 100vh;
  position: relative;
  width: 100%;
}
.App-logo-wrapper {
  animation: logo-shake 1.2s, logo-grow 0.7s ease-in-out;
  animation-iteration-count: 1;
  transform: scale(1);
  transition: transform .2s;
  max-width: 458px;
  width: 100%;
}
.App-logo-wrapper:hover {
  transform: scale(1.15);
}
.App-logo {
  height: auto;
  max-width: 100%;
  width: 56vw;
}

.App-logo:hover {
  animation: logo-shake 0.5s ease-in-out;
  animation-iteration-count: infinite;
}

.App-header {
  background-color: rgba(0,0,0,0.5);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-listen-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.App-listen-link span {
  margin: 0 .5rem;
}

@keyframes logo-grow {
  0% {
    transform: scale(0);
  }
  75% {
    transform: scale(1.15) rotate(-10deg);
  }
  100% {
    transform: scale(1)  rotate(0deg);
  }
}

@keyframes logo-shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}


