@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400&family=Roboto:wght@300;400&display=swap);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
html, body {
  width: 100%;
  height: 100vh;
  -webkit-overflow-scrolling: touch;
  margin: 0;
  padding: 0;
}
body {
  height: 100vh;
  overflow: hidden;
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //color: #FF0078;
  color: #FFF;
  background-image: url('https://theludlowgroup.com/content/images/2018/05/Liminal_Space_Truck_Stop.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
}
.bg1 {
  background-image: url('https://theludlowgroup.com/content/images/2018/05/Liminal_Space_Truck_Stop.jpg');
}

.bg2 {
  background-image: url('https://pm1.narvii.com/6805/3a0762ed87b1d7287b7cc28236b521fc3a888bfbv2_hq.jpg');
}

.bg3 {
  background-image:  url('https://thumbs.dreamstime.com/b/road-park-misty-night-road-park-misty-night-atmosphere-looks-mysterious-beautiful-same-215072515.jpg');
}

.bg4 {
  background-image: url(../../static/media/DSC_0121.db7f62e7.JPG);
}
/*.bg5 {
  background-image: url('https://images.fineartamerica.com/images/artworkimages/mediumlarge/2/nighttime-view-of-an-empty-side-street-amayfoto.jpg');
}*/
.bg5 {
  background-image: url('https://c0.wallpaperflare.com/preview/32/826/437/fun-odaiba-quiet-desolate.jpg');
}
.bg6 {
  background-image: url(../../static/media/bg6.df286a63.jpg);
}
h2 {
  font-weight: 300;
  letter-spacing: 2px;
  font-size: 1.5em;
  white-space: nowrap;
  display: flex;
  flex-shrink: 1;
  width: 100%;
  text-align: center;
  align-content: center;
  flex-direction: column;
}
@media all and (max-device-width: 410px){
    h2 {
        font-size: 1em;
    }
}
a {
  color: #75ffbe;
  text-decoration:  none;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
}

a:hover {
  color: #75ffbe;
  text-decoration:  underline;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rockPlayer {
  -webkit-animation: slide-up 1s forwards 1.5s;
          animation: slide-up 1s forwards 1.5s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  width: 100%;
  margin: 0 auto;
  padding: 5px 0 0 0;
  position: fixed;
  height: 45px;
  overflow: hidden;
  bottom: -50px;
  /*top: calc(100vh - 50px);*/
  background-color: rgba(0,0,0,0.4);
  z-index: 2;
}

@-webkit-keyframes slide-up {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    /*top: calc(100vh - 0px);*/
    opacity: 0;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    -webkit-transform: translateY(-45px);
            transform: translateY(-45px);
    opacity: 1;
    /*top: calc(100vh - 50px);*/
  }
}

@keyframes slide-up {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    /*top: calc(100vh - 0px);*/
    opacity: 0;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    -webkit-transform: translateY(-45px);
            transform: translateY(-45px);
    opacity: 1;
    /*top: calc(100vh - 50px);*/
  }
}

._wKo5X {
    display: flex;
    flex-direction: row;
    margin: 10px 0 10px 120px;
    width: 21vw;
    overflow: hidden;
    justify-content: space-between;
}
._wKo5X::before {
  content: 'Current Song:';
  font-family: 'Lato', sans-serif;
  font-size: 1.2rem;
  font-weight: 300;
  position: absolute;
  left: 20px;
}
._2_1sf {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-items: flex-start;
  width: 100%;
  flex: 1 1 100%;
  align-items: flex-start;
}

._oGACf {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  position: relative;
}

._1EWMa {
  padding: 0 66vw 0 20px;
  margin: 0 auto;
  height: 45px;
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
}


._3q4Ah {
  color: rgba(255,255,255,0.8);
  font-size: 1.2rem;
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-animation: marquee 15s linear infinite;
          animation: marquee 15s linear infinite;
  width: auto;
  white-space: nowrap;
}

._tAQkw {
  color: rgba(255,255,255,0.8);
  font-size: 1rem;
  font-weight: 300;
  margin: 0;
  font-family: 'Lato', sans-serif;
  text-align: right;
  display: flex;
  position: absolute;
  right: 20px;
  top: 8px;
}

@-webkit-keyframes marquee {
  0% {
      -webkit-transform: translateX(calc(210%));
  }
  100% {
      -webkit-transform: translateX(calc(-100%));
  }
}

@keyframes marquee {
  0% {
      -webkit-transform: translateX(calc(210%));
  }
  100% {
      -webkit-transform: translateX(calc(-100%));
  }
}
@-webkit-keyframes marquee-small {
  0% {
      -webkit-transform: translateX(calc(100%));
  }
  100% {
      -webkit-transform: translateX(calc(-100%));
  }
}
@keyframes marquee-small {
  0% {
      -webkit-transform: translateX(calc(100%));
  }
  100% {
      -webkit-transform: translateX(calc(-100%));
  }
}


._2cSIF {
  display: flex;
  width: 100%;
  position: absolute;
  top: -8px;
  left: 0px;
}

._mIY65 {
  --progressUsed: #FF0078;
  --progressSlider: #75ffbe;
  --progressLeft:  #626262;
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  border-radius: 0px;
  outline: none;
  opacity: 1;
  transition: opacity 0.2s;
  padding-bottom: 2px;
}

._mIY65:hover {
  cursor: pointer;
}

._mIY65::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 8px;
  
  background: var(--progressSlider);
  cursor: pointer;
}

._mIY65::-webkit-slider-thumb:hover {
  background: var(--progressSlider);
}

._mIY65::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #75ffbe;
  cursor: pointer;
}

.rockPlayer:hover ._mIY65::-webkit-slider-thumb {
  height: 18px;
}

._2FsrM {
    width: 30%;
    display: flex;
    flex-direction: row;
    flex: 1 1;
    justify-content: space-evenly;
    position: absolute;
    top: 10px;
    margin: 0 auto;
    right: auto;
    left: 31%;
}

._2FsrM img {
  width: 20px;
  height: 20px;
}

._3CCfI {
  width: 26px;
  height: 26px;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

._3CCfI:hover {
  cursor: pointer;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}



._3Juzi {
  width: 50px;
  height: 50px;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

._3Juzi:hover {
  cursor: pointer;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

._19ycA {
  width: 60px;
  height: 60px;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

._19ycA:hover {
  cursor: pointer;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

._1mI0y {
  width: 60px;
  height: 60px;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

._1mI0y:hover {
  cursor: pointer;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

._3a3Vy {
  width: 50px;
  height: 50px;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

._3a3Vy:hover {
  cursor: pointer;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

._8c4KL {
  width: 26px;
  height: 26px;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

._8c4KL:hover {
  cursor: pointer;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}


._1cpXy {
    width: 20vw;
    position: absolute;
    right: calc(23% - 10%);
    top: 7px;
}

._awgvT {
  --volumeSlider: #FF0078;
  -webkit-appearance: none;
  width: 70%;
  height: 3px;
  border-radius: 5px;
  background: var(--volumeSlider);
  outline: none;
  opacity: 1;
  transition: opacity 0.2s;
}

._awgvT::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--volumeSlider);
  cursor: pointer;
}

._awgvT::-webkit-slider-thumb:hover {
  background: var(--volumeSlider);
}

._awgvT::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #b951e9;
  cursor: pointer;
}


._RZMQZ {
  display: none;
}

/* width */
._RZMQZ::-webkit-scrollbar {
  width: 5px;
}

/* Track */
._RZMQZ::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
._RZMQZ::-webkit-scrollbar-thumb {
  background: var(--primaryText);
  border-radius: 10px;
}

@media only screen and (min-width: 768px) {
  ._RZMQZ {
    
  }
}

._1l2NE {
  background-color: var(--playlistBackground);
  color: var(--playlistText);
  font-family: 'Poppins', sans-serif;
  text-align: center;
  font-size: 16px;
  margin: 5px 0;
  padding: 3px 0;
  cursor: pointer;
  border-radius: 5px;
}

._1l2NE:first-child {
  margin: 0;
}

._2ybYQ {
  background-color: var(--playlistBackground);
  color: var(--playlistTextHoverActive);
  text-align: center;
  font-size: 18px;
  margin: 5px 0;
  padding: 3px 0;
  cursor: pointer;

  border-radius: 5px;
}

._2ybYQ:first-child {
  margin: 0;
}

._1l2NE:hover {
  background-color: var(--playlistBackground);
  color: var(--playlistTextHoverActive);
  border-radius: 5px;
}

._8a8Wj {
  display: none;
}

._1qrha {
  background-color: var(--tagsBackground);
  color: var(--tagsText);
  height: 40px;
  min-width: 100px;
  display: inline-grid;
  place-items: center;
  margin: 5px 5px;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  padding: 0 10px;
  font-family: 'Varela Round', sans-serif;
  border-radius: 10px;
  font-size: 18px;
}

._2AaIQ {
  background-color: var(--tagsBackgroundHoverActive);
  color: var(--tagsTextHoverActive);
  height: 40px;
  min-width: 100px;
  display: inline-grid;
  place-items: center;
  margin: 0 5px;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  padding: 0 10px;
  font-family: 'Varela Round', sans-serif;
  cursor: pointer;
  border-radius: 10px;
  font-size: 18px;
}

._1qrha:hover {
  background-color: var(--tagsBackgroundHoverActive);
  color: var(--tagsTextHoverActive);
  cursor: pointer;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

._3fz7s {
  text-align: center;
}

._1PreE {
  font-family: 'Quicksand', sans-serif;
  height: 40px;
  border: none;
  font-size: 18px;
  width: 100%;
  margin: 0 auto 10px auto;
  background-color: var(--searchBackground);
  color: var(--searchText);
  padding-left: 20px;
  border-radius: 10px;
}

._1PreE:-ms-input-placeholder {
  color: var(--searchPlaceHolder);
  opacity: 1;
}

._1PreE::-webkit-input-placeholder {
  color: var(--searchPlaceHolder);
  opacity: 1;
}

._1PreE::placeholder {
  color: var(--searchPlaceHolder);
  opacity: 1;
}

._1PreE:-ms-input-placeholder {
  color: var(--searchPlaceHolder);
}

._1PreE::-ms-input-placeholder {
  color: var(--searchPlaceHolder);
}
@media only screen and (max-width: 600px) {
  ._2FsrM {
    width: 35vw;
  }
  ._1cpXy {
    display: none;
  }
  ._1EWMa {
    padding: 0 50% 0 65px;
  }
  ._wKo5X {
    width: 100%;
    margin: 0px;
  }
  ._wKo5X::before {
    content: 'Song: ';
    top: 5px;
  }
  ._2FsrM {
    width: 30vw;
    right: 20vw;
    left: auto;
  }
  ._3q4Ah {
    width: 100vw;
    font-weight: 400;
    padding-top: 5px;
    max-width: none;
    -webkit-animation: marquee-small 15s linear infinite;
            animation: marquee-small 15s linear infinite;
  }
  ._tAQkw {
    position: absolute;
    right: 5px;
    top: 8px;
  }
}
.App {
  text-align: center;
  overflow: hidden;
  height: 100vh;
  position: relative;
  width: 100%;
}
.App-logo-wrapper {
  -webkit-animation: logo-shake 1.2s, logo-grow 0.7s ease-in-out;
          animation: logo-shake 1.2s, logo-grow 0.7s ease-in-out;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
  max-width: 458px;
  width: 100%;
}
.App-logo-wrapper:hover {
  -webkit-transform: scale(1.15);
          transform: scale(1.15);
}
.App-logo {
  height: auto;
  max-width: 100%;
  width: 56vw;
}

.App-logo:hover {
  -webkit-animation: logo-shake 0.5s ease-in-out;
          animation: logo-shake 0.5s ease-in-out;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

.App-header {
  background-color: rgba(0,0,0,0.5);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-listen-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.App-listen-link span {
  margin: 0 .5rem;
}

@-webkit-keyframes logo-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  75% {
    -webkit-transform: scale(1.15) rotate(-10deg);
            transform: scale(1.15) rotate(-10deg);
  }
  100% {
    -webkit-transform: scale(1)  rotate(0deg);
            transform: scale(1)  rotate(0deg);
  }
}

@keyframes logo-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  75% {
    -webkit-transform: scale(1.15) rotate(-10deg);
            transform: scale(1.15) rotate(-10deg);
  }
  100% {
    -webkit-transform: scale(1)  rotate(0deg);
            transform: scale(1)  rotate(0deg);
  }
}

@-webkit-keyframes logo-shake {
  0% { -webkit-transform: translate(1px, 1px) rotate(0deg); transform: translate(1px, 1px) rotate(0deg); }
  10% { -webkit-transform: translate(-1px, -2px) rotate(-1deg); transform: translate(-1px, -2px) rotate(-1deg); }
  20% { -webkit-transform: translate(-3px, 0px) rotate(1deg); transform: translate(-3px, 0px) rotate(1deg); }
  30% { -webkit-transform: translate(3px, 2px) rotate(0deg); transform: translate(3px, 2px) rotate(0deg); }
  40% { -webkit-transform: translate(1px, -1px) rotate(1deg); transform: translate(1px, -1px) rotate(1deg); }
  50% { -webkit-transform: translate(-1px, 2px) rotate(-1deg); transform: translate(-1px, 2px) rotate(-1deg); }
  60% { -webkit-transform: translate(-3px, 1px) rotate(0deg); transform: translate(-3px, 1px) rotate(0deg); }
  70% { -webkit-transform: translate(3px, 1px) rotate(-1deg); transform: translate(3px, 1px) rotate(-1deg); }
  80% { -webkit-transform: translate(-1px, -1px) rotate(1deg); transform: translate(-1px, -1px) rotate(1deg); }
  90% { -webkit-transform: translate(1px, 2px) rotate(0deg); transform: translate(1px, 2px) rotate(0deg); }
  100% { -webkit-transform: translate(1px, -2px) rotate(-1deg); transform: translate(1px, -2px) rotate(-1deg); }
}

@keyframes logo-shake {
  0% { -webkit-transform: translate(1px, 1px) rotate(0deg); transform: translate(1px, 1px) rotate(0deg); }
  10% { -webkit-transform: translate(-1px, -2px) rotate(-1deg); transform: translate(-1px, -2px) rotate(-1deg); }
  20% { -webkit-transform: translate(-3px, 0px) rotate(1deg); transform: translate(-3px, 0px) rotate(1deg); }
  30% { -webkit-transform: translate(3px, 2px) rotate(0deg); transform: translate(3px, 2px) rotate(0deg); }
  40% { -webkit-transform: translate(1px, -1px) rotate(1deg); transform: translate(1px, -1px) rotate(1deg); }
  50% { -webkit-transform: translate(-1px, 2px) rotate(-1deg); transform: translate(-1px, 2px) rotate(-1deg); }
  60% { -webkit-transform: translate(-3px, 1px) rotate(0deg); transform: translate(-3px, 1px) rotate(0deg); }
  70% { -webkit-transform: translate(3px, 1px) rotate(-1deg); transform: translate(3px, 1px) rotate(-1deg); }
  80% { -webkit-transform: translate(-1px, -1px) rotate(1deg); transform: translate(-1px, -1px) rotate(1deg); }
  90% { -webkit-transform: translate(1px, 2px) rotate(0deg); transform: translate(1px, 2px) rotate(0deg); }
  100% { -webkit-transform: translate(1px, -2px) rotate(-1deg); transform: translate(1px, -2px) rotate(-1deg); }
}



